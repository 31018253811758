<template>
  <div>
    <template v-for="item in schemeList">
      <BaseIndexVue :key="item.id" :item="item" :root="schemeList[0]" />
    </template>
  </div>
</template>

<script>
import {isMobile} from '@/utils/util.js'
export default {
  name: 'ApproverDetail',
  data() {
    return {
      // schemeList
      schemeList: [
        {
          id: this.$nanoid(),
          baseVue: 'BasePageMain',
          that: 'this',
          title: '我的审批-任务审核详情',
          back: true,
          data: {
            detail: {
              sceneAuditRecordId: '',
              title: '',
              sceneType: 1,
              createOrgName: '',
              applicantName: '',
              applicantOrgName: '',
              applyTime: '',
              applyType: 1,
              sceneTaskRuleVOS: [],
              sceneTaskMemberVOS: [],
              status: 0,
              applyRemark: '',
              rejectReason: '',
              reviewerName: '',
              auditTime: ''
            },
            statusText: '待审核',
            allMoney: 0,
            tableData: [],
            currentItem: {},
            isEdit: false,
            isVisible: false,
            isLoading: false,
            isVisibleAllot: false,
            reason: ''
          },
          init: function({ root, runInitFun }) {
            const { getDetail, getTableDataList } = root.initFun
            runInitFun(getDetail).then(res => {
              res?.applicantId && runInitFun(getTableDataList, res)
            })
          },
          initFun: {
            // 获取任务详情
            getDetail: function(options, { root, setRootItem, runInitFun }) {
              const params = this.$route.params
              return this.$axios
                .get(this.$API.sceneAuditDetail, { params: { sceneAuditRecordId: params.id } })
                .then(
                  res => {
                    const { getAllMoney } = root.initFun
                    const { data } = res
                    const { status } = res.data
                    const statusStr =
                      status === 1 ? '审核成功' : status === 2 ? '审核失败' : '待审核'
                    setRootItem(root, 'data.statusText', statusStr)
                    root.data.detail = {
                      ...data,
                      sceneTaskRuleVOS: data.sceneTaskRuleVOS || [],
                      sceneTaskMemberVOS: data.sceneTaskMemberVOS || []
                    }
                    root.title = `我的审核-${data.title}`
                    runInitFun(getAllMoney)
                    return data || {}
                  },
                  rea => this.$message.error(rea.message)
                )
            },
            // 请求table列表
            getTableDataList: function(options = {}, { root }) {
              this.$axios
                .get(this.$API.repLastMonthsSceneTask, {
                  params: { representId: options.applicantId }
                })
                .then(
                  res => {
                    console.log(res)
                    root.data.tableData = res.data
                  },
                  rea => this.$message.error(rea.message)
                )
            },
            // 计算总金额
            getAllMoney: function(options, { root, setRootItem }) {
              let num = 0
              if (root.data.detail.applyType === 1) {
                let count = 0
                root.data.detail.sceneTaskRuleVOS.map(it => {
                  if (!it.isRemoved) {
                    num += it.amount * it.count
                  } else {
                    ++count
                  }
                })
                if (count === root.data.detail.sceneTaskRuleVOS.length) {
                  num = 0
                }
              } else {
                const list = root.data.detail.sceneTaskMemberVOS.filter(it => !it.isRemoved)
                num = list.length * root.data.detail.sceneTaskRuleVOS[0].amount
              }
              setRootItem(root, 'data.allMoney', num)
            }
          },
          childSchemeList: [
            {
              id: this.$nanoid(),
              baseVue: 'BaseRow',
              colCount: 2,
              colStyle: function({ root }) {
                return [
                  {
                    borderRight: root.data.detail.status === 0 ? '1px solid #EEEEEE' : '',
                    minHeight: '90vh',
                    flexGrow: 2
                  },
                  {
                    flexFlow: 'column'
                  }
                ]
              },
              childSchemeList: [
                {
                  id: this.$nanoid(),
                  columnIndex: 1,
                  baseVue: 'BaseRow',
                  colCount: 1,
                  colStyle: [
                    {
                      flexFlow: 'column'
                    }
                  ],
                  style: {
                    width: '100%',
                    padding: '10px'
                  },
                  childSchemeList: [
                    {
                      id: this.$nanoid(),
                      columnIndex: 1,
                      baseVue: 'BaseSpan',
                      leftText: '活动名称：',
                      leftStyle: {
                        color: '#5a5a5a'
                      },
                      rightText: 'getRootItem(root,item.rightTextKey)',
                      rightTextKey: 'data.statusText',
                      rightStyle: function({ root }) {
                        const colType = {
                          '0': ['#0F0F0F', 'rgba(15, 15, 15, 0.1)'],
                          '1': ['#30C691', 'rgba(48,198,145,0.1)'],
                          '2': ['#E8312F', 'rgba(232, 49, 47, 0.1)']
                        }
                        return {
                          padding: '3px 10px',
                          color: colType[String(root.data.detail.status)][0],
                          background: colType[String(root.data.detail.status)][1],
                          marginLeft: '10px'
                        }
                      },
                      style: {
                        marginTop: '5px'
                      },
                      text: 'getRootItem(root,item.textKey)',
                      textKey: 'data.detail.title'
                    },
                    {
                      id: this.$nanoid(),
                      columnIndex: 1,
                      baseVue: 'BaseSpan',
                      leftText: '活动来源：',
                      leftStyle: {
                        color: '#5a5a5a'
                      },
                      style: {
                        marginTop: '10px'
                      },
                      text: 'getRootItem(root,item.textKey)',
                      textKey: 'data.detail.createOrgName'
                    },
                    {
                      id: this.$nanoid(),
                      columnIndex: 1,
                      baseVue: 'BaseSpan',
                      leftText: '申请人：',
                      leftStyle: {
                        color: '#5a5a5a'
                      },
                      style: {
                        marginTop: '10px'
                      },
                      text: 'getRootItem(root,item.textKey)',
                      textKey: 'data.detail.applicantName'
                    },
                    {
                      id: this.$nanoid(),
                      columnIndex: 1,
                      baseVue: 'BaseSpan',
                      leftText: '所属组织：',
                      leftStyle: {
                        color: '#5a5a5a'
                      },
                      style: {
                        marginTop: '10px'
                      },
                      text: 'getRootItem(root,item.textKey)',
                      textKey: 'data.detail.applicantOrgName'
                    },
                    {
                      id: this.$nanoid(),
                      columnIndex: 1,
                      baseVue: 'BaseSpan',
                      leftText: '申请时间：',
                      leftStyle: {
                        color: '#5a5a5a'
                      },
                      style: {
                        marginTop: '10px'
                      },
                      text: 'getRootItem(root,item.textKey)',
                      textKey: 'data.detail.applyTime'
                    },
                    {
                      id: this.$nanoid(),
                      columnIndex: 1,
                      baseVue: 'BaseSpan',
                      leftText: function({ root }) {
                        return root.data.detail.applyType === 1 ? '普通任务：' : '定向邀约：'
                      },
                      leftStyle: {
                        color: '#5a5a5a'
                      },
                      style: {
                        marginTop: '10px'
                      },
                      text: function({ root }) {
                        const { data: {
                          detail: {
                            applyType = 1,
                            doctorValidDay = 0,
                            representValidDay = 0,
                            sceneTaskRuleVOS = []
                          } = {}
                        } } = root
                        let count = 0
                        sceneTaskRuleVOS.map(it => {
                          if (!it.isRemoved) {
                            count += it.count
                          }
                        })
                        if (applyType === 1) {
                          return `共${count || 0}个任务`
                        } else {
                          return `医生有效期：${doctorValidDay}天，代表有效期：${representValidDay}天`
                        }
                      }
                    },
                    {
                      id: this.$nanoid(),
                      isMounted: function({ root }) {
                        return root.data.detail.applyType === 1
                      },
                      columnIndex: 1,
                      baseVue: 'BaseTable',
                      height: 300,
                      style: {
                        marginTop: '10px'
                      },
                      tableData: 'getRootItem(root,item.tableDataKey)',
                      tableDataKey: 'data.detail.sceneTaskRuleVOS',
                      tableColumn: [
                        {
                          label: '申请数量(个)',
                          prop: 'count',
                          align: 'center',
                          slot: {
                            column: [
                              {
                                id: this.$nanoid(),
                                baseVue: 'BaseSpan',
                                style: function({ item }) {
                                  return {
                                    color: item.row.isRemoved ? '#C8C8C8' : '#5a5a5a',
                                    textDecoration: item.row.isRemoved ? 'line-through' : '',
                                    justifyContent: 'center'
                                  }
                                },
                                text: function({ item }) {
                                  return item.row.count || '0'
                                }
                              }
                            ]
                          }
                        },
                        {
                          label: '代表有效期(天)',
                          prop: 'representValidDay',
                          align: 'center',
                          slot: {
                            column: [
                              {
                                id: this.$nanoid(),
                                baseVue: 'BaseSpan',
                                style: function({ item }) {
                                  return {
                                    color: item.row.isRemoved ? '#C8C8C8' : '#5a5a5a',
                                    textDecoration: item.row.isRemoved ? 'line-through' : '',
                                    justifyContent: 'center'
                                  }
                                },
                                text: function({ item }) {
                                  return item.row.representValidDay || '-'
                                }
                              }
                            ]
                          }
                        },
                        {
                          label: '医生有效期(天)',
                          prop: 'doctorValidDay',
                          align: 'center',
                          slot: {
                            column: [
                              {
                                id: this.$nanoid(),
                                baseVue: 'BaseSpan',
                                style: function({ item }) {
                                  return {
                                    color: item.row.isRemoved ? '#C8C8C8' : '#5a5a5a',
                                    textDecoration: item.row.isRemoved ? 'line-through' : '',
                                    justifyContent: 'center'
                                  }
                                },
                                text: function({ item }) {
                                  return item.row.doctorValidDay || '-'
                                }
                              }
                            ]
                          }
                        },
                        {
                          label: '操作',
                          prop: 'type',
                          align: 'center',
                          slot: {
                            header: [
                              {
                                id: this.$nanoid(),
                                baseVue: 'BaseSpan',
                                style: {
                                  justifyContent: 'center'
                                },
                                text: function({ root }) {
                                  return root.data.detail.status === 0 ? '操作' : '状态'
                                }
                              }
                            ],
                            column: [
                              {
                                id: this.$nanoid(),
                                isShow: function({ item, root }) {
                                  return !item.row.isRemoved && root.data.detail.status === 0
                                },
                                baseVue: 'BaseButton',
                                type: 'text',
                                text: '修改',
                                methods: {
                                  click: function(options, { root, setRootItem, deepClone }) {
                                    console.log(options)
                                    setRootItem(root, 'data.isEdit', true)
                                    setRootItem(
                                      root,
                                      'data.currentItem',
                                      deepClone(options.item.row)
                                    )
                                  }
                                }
                              },
                              {
                                id: this.$nanoid(),
                                isShow: function({ item, root }) {
                                  return item.row.isRemoved && root.data.detail.status === 0
                                },
                                baseVue: 'BaseButton',
                                type: 'text',
                                text: '恢复',
                                style: {
                                  justifyContent: 'center'
                                },
                                methods: {
                                  click: function(options, { root, runInitFun }) {
                                    const { row } = options.item
                                    row.isRemoved = false
                                    const { getAllMoney } = root.initFun
                                    runInitFun(getAllMoney)
                                  }
                                }
                              },
                              {
                                id: this.$nanoid(),
                                isShow: function({ item, root }) {
                                  return !item.row.isRemoved && root.data.detail.status === 0
                                },
                                baseVue: 'BaseButton',
                                type: 'text',
                                text: '删除',
                                style: {
                                  color: '#E8312F'
                                },
                                methods: {
                                  click: function(options, { root, runInitFun }) {
                                    const { row } = options.item
                                    row.isRemoved = true
                                    const { getAllMoney } = root.initFun
                                    runInitFun(getAllMoney)
                                  }
                                }
                              },
                              {
                                id: this.$nanoid(),
                                isShow: function({ root }) {
                                  return root.data.detail.status !== 0
                                },
                                baseVue: 'BaseSpan',
                                style: function({ item, root }) {
                                  return {
                                    justifyContent: 'center',
                                    color:
                                      root.data.detail.status === 1 && !item.row.isRemoved
                                        ? '#5a5a5a'
                                        : '#E8312F'
                                  }
                                },
                                text: function({ item, root }) {
                                  return root.data.detail.status === 1 && !item.row.isRemoved
                                    ? '申请成功'
                                    : '申请失败'
                                }
                              }
                            ]
                          }
                        }
                      ]
                    },
                    {
                      id: this.$nanoid(),
                      isMounted: function({ root }) {
                        return root.data.detail.applyType === 2
                      },
                      columnIndex: 1,
                      baseVue: 'BaseTable',
                      height: 300,
                      style: {
                        marginTop: '10px'
                      },
                      tableData: 'getRootItem(root,item.tableDataKey)',
                      tableDataKey: 'data.detail.sceneTaskMemberVOS',
                      tableColumn: [
                        {
                          label: '医生姓名',
                          prop: 'name',
                          fixed: 'left',
                          slot: {
                            column: [
                              {
                                id: this.$nanoid(),
                                baseVue: 'BaseSpan',
                                style: function({ item }) {
                                  return {
                                    color: item.row.isRemoved ? '#C8C8C8' : '#5a5a5a',
                                    textDecoration: item.row.isRemoved ? 'line-through' : ''
                                  }
                                },
                                text: function({ item }) {
                                  return item.row.name || '-'
                                }
                              }
                            ]
                          }
                        },
                        {
                          label: '职称',
                          prop: 'titleName',
                          align: 'center',
                          slot: {
                            column: [
                              {
                                id: this.$nanoid(),
                                baseVue: 'BaseSpan',
                                style: function({ item }) {
                                  return {
                                    color: item.row.isRemoved ? '#C8C8C8' : '#5a5a5a',
                                    textDecoration: item.row.isRemoved ? 'line-through' : '',
                                    justifyContent: 'center'
                                  }
                                },
                                text: function({ item }) {
                                  return item.row.titleName || '-'
                                }
                              }
                            ]
                          }
                        },
                        {
                          label: '科室',
                          prop: 'department',
                          align: 'center',
                          slot: {
                            column: [
                              {
                                id: this.$nanoid(),
                                baseVue: 'BaseSpan',
                                style: function({ item }) {
                                  return {
                                    color: item.row.isRemoved ? '#C8C8C8' : '#5a5a5a',
                                    textDecoration: item.row.isRemoved ? 'line-through' : '',
                                    justifyContent: 'center'
                                  }
                                },
                                text: function({ item }) {
                                  return item.row.department || '-'
                                }
                              }
                            ]
                          }
                        },
                        {
                          label: '医院',
                          prop: 'hospital',
                          align: 'center',
                          slot: {
                            column: [
                              {
                                id: this.$nanoid(),
                                baseVue: 'BaseSpan',
                                style: function({ item }) {
                                  return {
                                    color: item.row.isRemoved ? '#C8C8C8' : '#5a5a5a',
                                    textDecoration: item.row.isRemoved ? 'line-through' : '',
                                    justifyContent: 'center'
                                  }
                                },
                                text: function({ item }) {
                                  return item.row.hospital || '-'
                                }
                              }
                            ]
                          }
                        },
                        {
                          label: '操作',
                          prop: 'type',
                          align: 'center',
                          slot: {
                            header: [
                              {
                                id: this.$nanoid(),
                                baseVue: 'BaseSpan',
                                style: {
                                  justifyContent: 'center'
                                },
                                text: function({ root }) {
                                  return root.data.detail.status === 0 ? '操作' : '状态'
                                }
                              }
                            ],
                            column: [
                              {
                                id: this.$nanoid(),
                                isShow: function({ item, root }) {
                                  return item.row.isRemoved && root.data.detail.status === 0
                                },
                                baseVue: 'BaseButton',
                                type: 'text',
                                text: '恢复',
                                methods: {
                                  click: function(options, { root, runInitFun }) {
                                    const { row } = options.item
                                    row.isRemoved = false
                                    const { getAllMoney } = root.initFun
                                    runInitFun(getAllMoney)
                                  }
                                }
                              },
                              {
                                id: this.$nanoid(),
                                isShow: function({ item, root }) {
                                  return !item.row.isRemoved && root.data.detail.status === 0
                                },
                                baseVue: 'BaseButton',
                                type: 'text',
                                text: '删除',
                                style: {
                                  color: '#E8312F',
                                  marginLeft: '0'
                                },
                                methods: {
                                  click: function(options, { root, runInitFun }) {
                                    const { row } = options.item
                                    row.isRemoved = true
                                    const { getAllMoney } = root.initFun
                                    runInitFun(getAllMoney)
                                  }
                                }
                              },
                              {
                                id: this.$nanoid(),
                                isShow: function({ root }) {
                                  return root.data.detail.status !== 0
                                },
                                baseVue: 'BaseSpan',
                                style: function({ root, item }) {
                                  return {
                                    justifyContent: 'center',
                                    color:
                                      root.data.detail.status === 1 && !item.row.isRemoved
                                        ? '#5a5a5a'
                                        : '#E8312F'
                                  }
                                },
                                text: function({ root, item }) {
                                  return root.data.detail.status === 1 && !item.row.isRemoved
                                    ? '申请成功'
                                    : '申请失败'
                                }
                              }
                            ]
                          }
                        }
                      ]
                    },
                    {
                      id: this.$nanoid(),
                      columnIndex: 1,
                      baseVue: 'BaseSpan',
                      leftText: '审批备注：',
                      text: 'getRootItem(root,item.textKey)',
                      textKey: 'data.detail.applyRemark',
                      style: {
                        color: '#5a5a5a',
                        marginTop: '10px'
                      }
                    },

                    // 审批信息
                    {
                      id: this.$nanoid(),
                      baseVue: 'BaseRow',
                      isShow: function({ root }) {
                        return root.data.detail.status !== 0
                      },
                      columnIndex: 1,
                      colCount: 1,
                      colStyle: [
                        {
                          flexFlow: 'column'
                        }
                      ],
                      childSchemeList: [
                        {
                          id: this.$nanoid(),
                          columnIndex: 1,
                          baseVue: 'BaseSpan',
                          style: {
                            height: '1px',
                            background: '#F5F5F5',
                            margin: '10px 0',
                            width: '80%'
                          }
                        },
                        {
                          id: this.$nanoid(),
                          columnIndex: 1,
                          baseVue: 'BaseSpan',
                          leftText: '审批结果：',
                          leftStyle: {
                            color: '#5a5a5a'
                          },
                          style: function({ root }) {
                            return {
                              marginTop: '5px',
                              color: root.data.detail.status === 1 ? '#30C691' : '#E8312F'
                            }
                          },
                          text: 'getRootItem(root,item.textKey)',
                          textKey: 'data.statusText'
                        },
                        {
                          id: this.$nanoid(),
                          isShow: function({ root }) {
                            return root.data.detail.status === 2
                          },
                          columnIndex: 1,
                          baseVue: 'BaseSpan',
                          leftText: '驳回原因：',
                          leftStyle: {
                            color: '#5a5a5a'
                          },
                          style: function({ root }) {
                            return {
                              marginTop: '10px',
                              color: root.data.detail.status === 1 ? '#30C691' : '#E8312F'
                            }
                          },
                          text: 'getRootItem(root,item.textKey)',
                          textKey: 'data.detail.rejectReason'
                        },
                        {
                          id: this.$nanoid(),
                          columnIndex: 1,
                          baseVue: 'BaseSpan',
                          leftText: '审批人：',
                          leftStyle: {
                            color: '#5a5a5a'
                          },
                          style: {
                            marginTop: '10px'
                          },
                          text: 'getRootItem(root,item.textKey)',
                          textKey: 'data.detail.reviewerName'
                        },
                        {
                          id: this.$nanoid(),
                          columnIndex: 1,
                          baseVue: 'BaseSpan',
                          leftText: '审批时间：',
                          leftStyle: {
                            color: '#5a5a5a'
                          },
                          style: {
                            marginTop: '10px'
                          },
                          text: 'getRootItem(root,item.textKey)',
                          textKey: 'data.detail.reviewAt'
                        },
                        {
                          id: this.$nanoid(),
                          isShow: function({ root }) {
                            return root.data.detail.status === 1
                          },
                          columnIndex: 1,
                          baseVue: 'BaseSpan',
                          leftText: '分配状态：',
                          leftStyle: {
                            color: '#5a5a5a'
                          },
                          style: {
                            marginTop: '10px'
                          },
                          text: function({ root }) {
                            const obj = {
                              '1': '完成分配',
                              '2': '分配中',
                              '3': '分配失败',
                              '4': '待分配',
                              '5': '待分配'
                            }
                            return obj[String(root.data.detail.assignStatus)]
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  id: this.$nanoid(),
                  isShow: function({ root }) {
                    return root.data.detail.status === 0
                  },
                  columnIndex: 2,
                  baseVue: 'BaseRow',
                  colCount: 1,
                  colStyle: [
                    {
                      flexFlow: 'column'
                    }
                  ],
                  style: {
                    padding: '10px 10px 0',
                    width: isMobile() ? '100%' : '500px'
                  },
                  childSchemeList: [
                    {
                      id: this.$nanoid(),
                      columnIndex: 1,
                      baseVue: 'BaseSpan',
                      text: 'getRootItem(root,item.textKey)最近3个月的任务申请',
                      textKey: 'data.detail.applicantName'
                    },
                    {
                      id: this.$nanoid(),
                      columnIndex: 1,
                      baseVue: 'BaseTable',
                      border: true,
                      style: {
                        marginTop: '10px'
                      },
                      tableData: 'getRootItem(root,item.tableDataKey)',
                      tableDataKey: 'data.tableData',
                      tableColumn: [
                        {
                          label: '申请时间',
                          prop: 'applyTime',
                          fixed: 'left'
                        },
                        {
                          label: '申请任务数',
                          prop: 'applyCount',
                          align: 'center'
                        },
                        {
                          label: '申请状态',
                          prop: 'type',
                          align: 'center',
                          slot: {
                            column: [
                              {
                                id: this.$nanoid(),
                                baseVue: 'BaseSpan',
                                style: function({ item }) {
                                  const obj = {
                                    '0': '#FF7600',
                                    '1': '#5a5a5a',
                                    '2': '#E8312F'
                                  }
                                  return {
                                    justifyContent: 'center',
                                    color: obj[String(item.row.status)]
                                  }
                                },
                                text: function({ item }) {
                                  const obj = {
                                    '0': '待审核',
                                    '1': '审核成功',
                                    '2': '审核失败'
                                  }
                                  return obj[String(item.row.status)] || '-'
                                }
                              }
                            ]
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            // 批量驳回弹窗
            {
              id: this.$nanoid(),
              baseVue: 'BaseDialog',
              visible: 'getRootItem(root,item.visibleKey)',
              visibleKey: 'data.isVisible',
              title: '驳回原因',
              width: '30%',
              content: [
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseInput',
                  type: 'textarea',
                  placeholder: '请填写驳回原因',
                  autosize: { minRows: 10, maxRows: 20 },
                  maxlength: 200,
                  methods: {
                    input: function(options, { root, setRootItem }) {
                      setRootItem(root, 'data.reason', options.value)
                    }
                  }
                }
              ],
              footer: [
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseButton',
                  type: ' info',
                  plain: true,
                  text: '取消',
                  style: {
                    width: '92px'
                  },
                  methods: {
                    click: `function({item} = options, {setRootItem, root}) {
                        setRootItem(root, 'data.isVisible', false)
                      }`
                  }
                },
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseButton',
                  type: 'primary',
                  text: '完成',
                  style: {
                    width: '92px'
                  },
                  methods: {
                    click: function(options, { setRootItem, root, runInitFun }) {
                      if (!root.data.reason) return this.$message.error('请填写驳回原因')
                      const data = {
                        sceneAuditRecordIds: [root.data.detail.sceneAuditRecordId],
                        reason: root.data.reason
                      }
                      this.$axios.post(this.$API.rejectSceneTaskApply, data).then(
                        () => {
                          setRootItem(root, 'data.isVisible', false)
                          this.$message.success('驳回成功')
                          this.$EventBus.$emit('getList')
                          const { getDetail } = root.initFun
                          runInitFun(getDetail)
                        },
                        rea => this.$message.error(rea.message)
                      )
                    }
                  }
                }
              ],
              methods: {
                handleClose: `function({item} = options, {setRootItem, root}) {
                  setRootItem(root, item.visibleKey, false)
                }`
              }
            },
            // 通过并分配 - 普通任务
            {
              id: this.$nanoid(),
              baseVue: 'BaseDialog',
              visible: 'getRootItem(root,item.visibleKey)',
              visibleKey: 'data.isVisibleAllot',
              title: '确认分配学术任务',
              width: '50%',
              content: [
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseSpan',
                  text: function({ root }) {
                    return root.data.detail.title
                  },
                  leftText: '任务名称：'
                },
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseSpan',
                  style: {
                    marginTop: '5px'
                  },
                  text: function({ root }) {
                    let num = 0
                    if (root.data.detail.applyType === 1) {
                      root.data.detail.sceneTaskRuleVOS.map(it => {
                        if (!it.isRemoved) {
                          num += it.count
                        }
                      })
                      return `1名代表，${num}个任务`
                    } else {
                      num = root.data.detail.sceneTaskMemberVOS.length
                      return `${num}名医生`
                    }
                  },
                  leftText: '任务对象：'
                },
                {
                  id: this.$nanoid(),
                  isShow: function({ root }) {
                    return root.data.detail.applyType === 1
                  },
                  baseVue: 'BaseTable',
                  height: 350,
                  style: {
                    marginTop: '10px'
                  },
                  tableData: function({ root }) {
                    return root.data.detail.sceneTaskRuleVOS.filter(it => !it.isRemoved)
                  },
                  tableColumn: [
                    {
                      label: '员工姓名',
                      prop: 'applicantName',
                      showOverflowTooltip: true,
                      slot: {
                        column: [
                          {
                            id: this.$nanoid(),
                            baseVue: 'BaseSpan',
                            text: function({ root }) {
                              return root.data.detail.applicantName
                            }
                          }
                        ]
                      }
                    },
                    {
                      label: '所属组织',
                      prop: 'applicantOrgName',
                      align: 'center',
                      showOverflowTooltip: true,
                      slot: {
                        column: [
                          {
                            id: this.$nanoid(),
                            baseVue: 'BaseSpan',
                            style: {
                              justifyContent: 'center'
                            },
                            text: function({ root }) {
                              return root.data.detail.applicantOrgName
                            }
                          }
                        ]
                      }
                    },
                    {
                      label: '任务数量(个)',
                      prop: 'count',
                      align: 'center'
                    },
                    {
                      label: '任务规则',
                      prop: 'taskRules',
                      align: 'center',
                      showOverflowTooltip: true,
                      slot: {
                        column: [
                          {
                            id: this.$nanoid(),
                            baseVue: 'BaseSpan',
                            style: {
                              justifyContent: 'center'
                            },
                            text: function({ item }) {
                              return `${item.row.representValidDay}天/${item.row.doctorValidDay}天`
                            }
                          }
                        ]
                      }
                    }
                  ]
                },
                // 通过并分配 - 定向邀约
                {
                  id: this.$nanoid(),
                  isShow: function({ root }) {
                    return root.data.detail.applyType === 2
                  },
                  baseVue: 'BaseTable',
                  height: 350,
                  style: {
                    marginTop: '10px'
                  },
                  tableData: function({ root }) {
                    return root.data.detail.sceneTaskMemberVOS.filter(it => !it.isRemoved)
                  },
                  tableColumn: [
                    {
                      label: '医生姓名',
                      prop: 'name',
                      showOverflowTooltip: true,
                      slot: {
                        column: [
                          {
                            id: this.$nanoid(),
                            baseVue: 'BaseSpan',
                            text: function({ item }) {
                              return item.row.name || '-'
                            }
                          }
                        ]
                      }
                    },
                    {
                      label: '所属代表',
                      prop: 'applicantName',
                      showOverflowTooltip: true,
                      slot: {
                        column: [
                          {
                            id: this.$nanoid(),
                            baseVue: 'BaseSpan',
                            text: function({ root }) {
                              return root.data.detail.applicantName
                            }
                          }
                        ]
                      }
                    },
                    {
                      label: '所属组织',
                      prop: 'applicantOrgName',
                      align: 'center',
                      showOverflowTooltip: true,
                      slot: {
                        column: [
                          {
                            id: this.$nanoid(),
                            baseVue: 'BaseSpan',
                            style: {
                              justifyContent: 'center'
                            },
                            text: function({ root }) {
                              return root.data.detail.applicantOrgName
                            }
                          }
                        ]
                      }
                    },
                    {
                      label: '任务规则',
                      prop: 'taskRules',
                      align: 'center',
                      showOverflowTooltip: true,
                      slot: {
                        column: [
                          {
                            id: this.$nanoid(),
                            baseVue: 'BaseSpan',
                            style: {
                              justifyContent: 'center'
                            },
                            text: function({ root }) {
                              const item = root.data.detail.sceneTaskRuleVOS[0]
                              return `${item.representValidDay}天/${item.doctorValidDay}天`
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              ],
              footer: [
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseButton',
                  type: ' info',
                  plain: true,
                  text: '返回修改',
                  methods: {
                    click: `function({item} = options, {setRootItem, root}) {
                        setRootItem(root, 'data.isVisibleAllot', false)
                      }`
                  }
                },
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseButton',
                  loading: 'getRootItem(root,item.loadingKey)',
                  loadingKey: 'data.isLoading',
                  type: 'primary',
                  text: '确认分配',
                  methods: {
                    click: function(options, { setRootItem, root, runInitFun }) {
                      setRootItem(root, 'data.isLoading', true)
                      const data = {
                        sceneAuditRecordId: root.data.detail.sceneAuditRecordId,
                        sceneTaskMemberBOS: root.data.detail.sceneTaskMemberVOS,
                        sceneTaskRuleBOS: root.data.detail.sceneTaskRuleVOS
                      }
                      this.$axios.post(this.$API.passAssignSceneTaskApply, data).then(
                        () => {
                          setRootItem(root, 'data.isLoading', false)
                          setRootItem(root, 'data.isVisibleAllot', false)
                          this.$message.success('通过并分配成功')
                          this.$EventBus.$emit('getList')
                          const { getDetail } = root.initFun
                          runInitFun(getDetail)
                        },
                        rea => {
                          setRootItem(root, 'data.isLoading', false)
                          this.$message.error(rea.message)
                        }
                      )
                    }
                  }
                }
              ],
              methods: {
                handleClose: `function({item} = options, {setRootItem, root}) {
                  setRootItem(root, item.visibleKey, false)
                }`
              }
            },
            {
              id: this.$nanoid(),
              baseVue: 'BaseDialog',
              visible: 'getRootItem(root,item.visibleKey)',
              visibleKey: 'data.isEdit',
              title: '修改任务',
              width: '30%',
              center: true,
              content: [
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseRow',
                  colCount: 1,
                  colStyle: [
                    {
                      display: 'flex',
                      flexFlow: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }
                  ],
                  childSchemeList: [
                    {
                      id: this.$nanoid(),
                      columnIndex: 1,
                      baseVue: 'BaseInputNumber',
                      value: 'getRootItem(root,item.key)',
                      key: 'data.currentItem.count',
                      step: 1,
                      style: {
                        width: '300px',
                        marginTop: '20px'
                      },
                      leftText: '申请数量',
                      rightText: '个',
                      leftStyle: {
                        width: '80px',
                        textAlign: 'center'
                      },
                      rightStyle: {
                        width: '40px',
                        textAlign: 'center'
                      },
                      methods: {
                        change: function(options, { root, setRootItem }) {
                          setRootItem(root, 'data.currentItem.count', options.value)
                        }
                      }
                    },
                    {
                      id: this.$nanoid(),
                      columnIndex: 1,
                      baseVue: 'BaseInputNumber',
                      value: 'getRootItem(root,item.key)',
                      key: 'data.currentItem.representValidDay',
                      step: 1,
                      min: 0,
                      max: 60,
                      style: {
                        width: '300px',
                        marginTop: '20px'
                      },
                      leftText: '代表有效期',
                      rightText: '天',
                      leftStyle: {
                        width: '80px',
                        textAlign: 'center'
                      },
                      rightStyle: {
                        width: '40px',
                        textAlign: 'center'
                      },
                      methods: {
                        change: function(options, { root, setRootItem }) {
                          setRootItem(root, 'data.currentItem.representValidDay', options.value)
                        }
                      }
                    },
                    {
                      id: this.$nanoid(),
                      columnIndex: 1,
                      baseVue: 'BaseInputNumber',
                      value: 'getRootItem(root,item.key)',
                      key: 'data.currentItem.doctorValidDay',
                      step: 1,
                      min: 0,
                      max: 30,
                      style: {
                        width: '300px',
                        marginTop: '20px',
                        marginBottom: '20px'
                      },
                      leftText: '医生有效期',
                      rightText: '天',
                      leftStyle: {
                        width: '80px',
                        textAlign: 'center'
                      },
                      rightStyle: {
                        width: '40px',
                        textAlign: 'center'
                      },
                      methods: {
                        change: function(options, { root, setRootItem }) {
                          setRootItem(root, 'data.currentItem.doctorValidDay', options.value)
                        }
                      }
                    }
                  ]
                }
              ],
              footer: [
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseButton',
                  type: ' info',
                  plain: true,
                  text: '取消修改',
                  methods: {
                    click: `function({item} = options, {setRootItem, root}) {
                        setRootItem(root, 'data.isEdit', false)
                      }`
                  }
                },
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseButton',
                  loading: 'getRootItem(root,item.loadingKey)',
                  loadingKey: 'data.isLoading',
                  type: 'primary',
                  text: '确认修改',
                  methods: {
                    click: function(options, { root, setRootItem, runInitFun }) {
                      const list = [...root.data.detail.sceneTaskRuleVOS]
                      for (let i = 0; i < list.length; i++) {
                        if (root.data.currentItem.id === list[i].id) {
                          list[i] = root.data.currentItem
                          setRootItem(root, 'data.detail.sceneTaskRuleVOS', list)
                          setRootItem(root, 'data.isEdit', false)
                          const { getAllMoney } = root.initFun
                          runInitFun(getAllMoney)
                          return
                        }
                      }
                    }
                  }
                }
              ],
              methods: {
                handleClose: `function({item} = options, {setRootItem, root}) {
                  setRootItem(root, item.visibleKey, false)
                }`
              }
            }
          ]
        },
        {
          isShow: function({ root }) {
            return root.data.detail.status === 0
          },
          baseVue: 'BaseFooter',
          childSchemeList: [
            {
              id: this.$nanoid(),
              baseVue: 'BaseButton',
              type: 'error',
              plain: true,
              text: '驳回',
              style: {
                width: '92px',
                color: '#E8312F',
                borderColor: '#E8312F'
              },
              methods: {
                click: function(options, { root, setRootItem }) {
                  setRootItem(root, 'data.isVisible', true)
                }
              }
            },
            {
              id: this.$nanoid(),
              baseVue: 'BaseButton',
              type: 'primary',
              plain: true,
              text: '通过并分配',
              methods: {
                click: function(options, { root, setRootItem }) {
                  if (
                    root.data.detail.applyType === 1 &&
                    !root.data.detail.sceneTaskRuleVOS.filter(it => !it.isRemoved).length
                  ) {
                    return this.$message.error('请至少保留一条任务规则')
                  }
                  if (
                    root.data.detail.applyType === 2 &&
                    !root.data.detail.sceneTaskMemberVOS.filter(it => !it.isRemoved).length
                  ) {
                    return this.$message.error('请至少保留一位分配医生')
                  }
                  setRootItem(root, 'data.isVisibleAllot', true)
                }
              }
            },
            {
              id: this.$nanoid(),
              baseVue: 'BaseButton',
              type: 'primary',
              text: '通过并发起委托调研',
              methods: {
                click: function(options, { root }) {
                  if (
                    root.data.detail.applyType === 1 &&
                    !root.data.detail.sceneTaskRuleVOS.filter(it => !it.isRemoved).length
                  ) {
                    return this.$message.error('请至少保留一条任务规则')
                  }
                  if (
                    root.data.detail.applyType === 2 &&
                    !root.data.detail.sceneTaskMemberVOS.filter(it => !it.isRemoved).length
                  ) {
                    return this.$message.error('请至少保留一位分配医生')
                  }
                  const data = {
                    sceneAuditRecordId: root.data.detail.sceneAuditRecordId,
                    sceneTaskMemberBOS: root.data.detail.sceneTaskMemberVOS,
                    sceneTaskRuleBOS: root.data.detail.sceneTaskRuleVOS
                  }
                  this.$axios.post(this.$API.updateSceneTaskAudit, data).then(
                    () => {
                      const sceneType = this.sceneType(root.data.detail.sceneType)
                      this.$router.push({
                        path: `/academic/scene/${sceneType}/entrust/${root.data.detail.itemId}`,
                        query: {
                          sceneAuditRecordId: root.data.detail.sceneAuditRecordId
                        }
                      })
                    },
                    rea => {
                      this.$message.error(rea.message)
                    }
                  )
                }
              }
            }
          ]
        }
      ]
    }
  },
  computed: {

  },
  created() {
    this.schemeList[0].that = this
  },
  methods: {
    sceneType(type) {
      switch (type) {
        case 1:
          return 'meeting'
        case 4:
          return 'questionnaire'
        case 5:
          return 'live'
        case 6:
          return 'webinar'
        case 8:
          return 'academicVisit'
        case 7:
          return 'training'
        default:
          return '-'
      }
    }
  }
}
</script>
