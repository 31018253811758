import factoryLiveAudit from './factory-liveAudit'
import factorySceneAudit from './factory-sceneAudit'
import factoryV1Scene from './factory-v1-scene'
import customerVisit from './customer-visit'

export default {
  ...factoryLiveAudit,
  ...factorySceneAudit,
  ...factoryV1Scene,
  ...customerVisit
}
