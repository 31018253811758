import {VUE_APP_GATEWAY_QXS} from './api-types'

export default {
  sceneShare: {
    apiPath: `${VUE_APP_GATEWAY_QXS}/factory/v1/scene/share`,
    api: 'sceneShare',
    method: 'post',
    data: {
      sceneIds: [],   // 活动id列表
      sceneType: '',  // 活动类型 meeting: '科会',questionnaire: '调研',live: '直播',webinar: '会议' academicVisit: '拜访'
      factoryIds: [], // 厂家id列表
      isSelectedPubResLib: false      // 是否分享公共资源库
    }
  }
}
