import {VUE_APP_GATEWAY_QXS} from './api-types'

export default {
  customerVisitList: {
    apiPath: `${VUE_APP_GATEWAY_QXS}/org/customer-visit/list`,
    api: 'customerVisitList',
    method: 'post',
    data: {
      pageNo: 1,   // 分页
      pageSize: 10,  //
      keyword: '', // 关键字
      orgId: '',      // 组织ID
      startTime: '',      // 开始时间 YYYY-MM-DD
      endTime: ''      // 结束时间 YYYY-MM-DD
    }
  }
}
