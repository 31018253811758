// const VUE_APP_GATEWAY_KSH = process.env.VUE_APP_GATEWAY_KSH // 科室会网关
import {VUE_APP_GATEWAY_QXS} from './api-types'

export default {
  // 导出筛选的审核数据
  exportSceneAuditData: {
    apiPath: `${VUE_APP_GATEWAY_QXS}/factory/scene-audit/export-scene-audit-data`,
    api: 'exportSceneAuditData',
    method: 'post',
    data: {
      pageNo: 1,        // 非 页数，默认1
      pageSize: 10,      // 非 每页大小，默认10
      applyType: null,         // integer 非 申请任务类型，1：普通任务申请；2：定向任务申请
      auditStartTime: '',     // 非 审核开始时间
      auditEndTime: '',       // 非 审核结束时间
      sceneType: null,     // integer 必 场景类型，1：科会，4：调研/病例5：直播，6：会议
      auditStatus: null,   // integer 非 审核状态，0：待审核（审核中），1：审核通过；2：审核失败
      searchKey: '',      // 非 活动名称/申请人
      tagAndGroupIds: [] // 产品标签
    }
  },
  // 学术任务审核列表
  sceneAuditList: {
    apiPath: `${VUE_APP_GATEWAY_QXS}/factory/scene-audit/scene-audit-list`,
    api: 'sceneAuditList',
    method: 'post',
    data: {
      pageNo: 1,        // 非 页数，默认1
      pageSize: 10,      // 非 每页大小，默认10
      applyType: null,         // integer 非 申请任务类型，1：普通任务申请；2：定向任务申请
      auditStartTime: '',     // 非 审核开始时间
      auditEndTime: '',       // 非 审核结束时间
      sceneType: null,     // integer 必 场景类型，1：科会，4：调研/病例5：直播，6：会议
      auditStatus: null,   // integer 非 审核状态，0：待审核（审核中），1：审核通过；2：审核失败
      searchKey: '',      // 非 活动名称/申请人
      tagAndGroupIds: [] // 产品标签
    }
  },
  // 厂家批量驳回代表申请的学术任务资源
  rejectSceneTaskApply: {
    apiPath: `${VUE_APP_GATEWAY_QXS}/factory/scene-audit/batch-reject-scene-task-apply`,
    api: 'rejectSceneTaskApply',
    method: 'post',
    data: {
      reason: '',   // 必 驳回原因
      sceneAuditRecordIds: []  // 非 驳回的申请记录ids
    }
  },
  // 厂家批量通过代表申请的学术任务资源
  passSceneTaskApply: {
    apiPath: `${VUE_APP_GATEWAY_QXS}/factory/scene-audit/batch-success-and-assign-scene-task`,
    api: 'passSceneTaskApply',
    method: 'post',
    data: []    // 审核记录ids
  }

}
