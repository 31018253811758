import {VUE_APP_GATEWAY_QXS} from './api-types'

export default {
  // 厂家直播审核分页列表
  liveAuditList: {
    apiPath: `${VUE_APP_GATEWAY_QXS}/factory/live-audit/v1/live-audit-list`,
    api: 'liveAuditList',
    method: 'post',
    data: {
      pageNo: 1,        // 非 页数，默认1
      pageSize: 10,      // 非 每页大小，默认10
      auditStartTime: '',     // 必
      auditEndTime: '',       // 必
      sceneType: 5,     // 必 场景类型，5：直播，6：会议
      auditStatus: null,   // 非 审核状态，0：待审核（审核中），1：审核通过；2：审核失败
      searchKey: '',      // 非 活动名称/申请人
      tagAndGroupIds: [] // 非 产品标签
    }
  },
  // 导出所有直播审核列表数据
  exportLiveAuditData: {
    apiPath: `${VUE_APP_GATEWAY_QXS}/factory/live-audit/v1/export-live-audit-data`,
    api: 'exportLiveAuditData',
    method: 'post',
    data: {
      auditStatus: '',  // 审核状态
      searchKey: '',  // 搜索关键字
      auditStartTime: '',     // 必
      auditEndTime: '',       // 必
      sceneType: 5,     // 必 场景类型，5：直播，6：会议
      tagAndGroupIds: [] // 产品标签
    }
  },
  // 驳回代表创建直播的申请
  rejectLiveCreate: {
    apiPath: `${VUE_APP_GATEWAY_QXS}/factory/live-audit/v1/reject-live-create`,
    api: 'rejectLiveCreate',
    method: 'post',
    data: {
      reason: '',   // 必 驳回原因
      liveAuditRecordIds: []  // 非 驳回的申请记录ids
    }
  },
  // 批量通过代表创建直播/会议的申请
  batchSuccessLiveCreate: {
    apiPath: `${VUE_APP_GATEWAY_QXS}/factory/live-audit/v1/batch_success-live-create`,
    api: 'batchSuccessLiveCreate',
    method: 'post',
    data: {
      liveAuditRecordIds: []  // 审核记录ids
    }
  }
}
